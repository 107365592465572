<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="is_default" slot-scope="is_default">
        <span class="address-default">{{ is_default ? '默认' : '' }}</span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import { findReceiveAddresses } from '@/api/receive_address'
import Pagination from '@/components/Pagination'

export default {
  name: 'ReceiveAddressesList',
  components: {
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '',
          dataIndex: 'is_default',
          scopedSlots: { customRender: 'is_default' },
          fixed: 'left',
          width: 100
        },
        {
          title: '收货人',
          dataIndex: 'receiver'
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '所在地区',
          dataIndex: 'region_display'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '详细地址',
          dataIndex: 'address_detail',
          width: 300
        }
      ]
    }
  },
  methods: {
    // 排序
    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findReceiveAddresses(
        Object.assign({ agent_id: this.agentId }, this.query, this.sort)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.address-default {
  color: @primary-color;
}
</style>

