import request from '@/utils/request'

// 查询客户收货地址
export function findReceiveAddresses(params) {
  return request({
    url: `/receive_addresses`,
    method: 'get',
    params: params
  })
}

